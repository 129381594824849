







































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImgUpload from "@components/ImgUpload.vue";
import PopupPreviewVideo from "@components/PopupPreviewVideo.vue";
import { Form as ElForm } from "element-ui";

import { IMediaModel } from "@/data/commonModel";
import {
  importExternalVideo,
  addViceBanner,
  updateViceBanner,
} from "@/remote/banner";
import { getVideo } from "@/remote/video";
import { IViceBannerReqModel, IMainBannerModel } from "../data/bannerModel";

interface IBannerFormModel {
  type: number;
  title: string;
  redirectLink: string;
  appId: string;
  bannerImages: IMediaModel[];
  videoId: string;
  roomId: string;
  btnText: string;
  btnTextBefore: string;
  btnTextOnGoing: string;
  btnTextAfter: string;
  redirectToCRM: Boolean;
}

// 装饰器模式
@Component({
  components: {
    ImgUpload,
    PopupPreviewVideo,
    ElForm,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "add",
  })
  editType!: string;
  // editType = 'edit'时所需要的数据
  @Prop() bannerModel!: IMainBannerModel;

  // Data
  formLabelWidth: string = "140px";
  formValueWidth: number = 450;
  formParams: IBannerFormModel = {
    type: 0, // link: 0 video: 1 liveStreaming: 2
    title: "",
    redirectLink: "",
    appId: "",
    bannerImages: [],
    videoId: "",
    roomId: "",
    btnText: "",
    btnTextBefore: "",
    btnTextOnGoing: "",
    btnTextAfter: "",
    redirectToCRM: false,
  };

  bannerId: string = "";
  saveBtnLoading: boolean = false;
  titleLength: number = 0;
  subTitleLength: number = 0;
  videoMaxSize: number = 1024 * 49;
  previewDialogVisible: boolean = false;

  itemErrorFlags = {
    title: false,
    roomId: false,
    btnText: false,
    btnTextBefore: false,
    btnTextOnGoing: false,
    btnTextAfter: false,
  };

  // Rules
  rules = {
    title: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入Banner名称",
    },
    btnText: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入按钮文字",
    },
    // ,
    // videoBtnText: {
    //   type: 'string',
    //   required: true,
    //   trigger: 'blur',
    //   message: '请输入按钮文字',
    // },
    roomId: {
      required: true,
      trigger: "blur",
      message: "请输入直播间房间ID",
    },
    redirectLink: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输跳转链接",
    },
    redirectLink2: {
      type: "string",
      required: false,
      trigger: "blur",
      message: "请输入页面路径,为空则跳转到首页",
    },
    appId: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入要跳转的小程序appId",
    },
    bannerImages: {
      type: "array",
      required: true,
      trigger: "focus",
      message: "请选择Banner图片",
    },
    videoId: {
      type: "string",
      required: true,
      trigger: "focus",
      message: "请输入视频Id",
    },
    btnTextBefore: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入直播开始前状态时按钮文字",
    },
    btnTextOnGoing: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入直播进行中状态时按钮文字",
    },
    btnTextAfter: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入直播结束状态时按钮文字",
    },
  };

  // Computed
  get uploadImageItemWidth() {
    return `${(this.formValueWidth - 20) / 5}px`;
  }

  // Watch
  @Watch("bannerModel", {
    immediate: true,
    deep: true,
  })
  onBannerModelChange(value: IMainBannerModel) {
    if (!value) return;
    const model = JSON.parse(JSON.stringify(value));
    this.bannerId = model.id;

    var btnTexts;
    if (model.type === 2 && model.buttonStatusText.length > 0) {
      btnTexts = model.buttonStatusText.split(",");
    }

    this.formParams = {
      type: model.type, // link: 0 video: 1 liveStreaming: 2
      title: model.name,
      appId: model.appId,
      redirectLink: model.redirectLink,
      bannerImages: model.image ? [model.image] : [],
      videoId: model.type === 1 ? model.video.externalVideoId : "",
      roomId: model.type === 2 ? model.roomId : "",
      btnText: model.type !== 2 ? model.buttonText : "",
      btnTextBefore: btnTexts && btnTexts[0],
      btnTextOnGoing: btnTexts && btnTexts[1],
      btnTextAfter: btnTexts && btnTexts[2],
      redirectToCRM: model.redirectToCRM,
    };
  }

  @Watch("formParams.type", { immediate: true, deep: true })
  onTypeChange(value: number) {
    this.itemErrorFlags = {
      title: this.itemErrorFlags.title,
      roomId: false,
      btnText: false,
      btnTextBefore: false,
      btnTextOnGoing: false,
      btnTextAfter: false,
    };

    const form = this.$refs["bannerForm"] as any;
    if (form && value !== 2 && form.fields[3].validateState === "error") {
      form.fields[3].validateState = "success";
    }
  }

  @Watch("formParams", {
    immediate: true,
    deep: true,
  })
  onFormParamsChange(value: IBannerFormModel) {
    const { bannerImages, videoId } = value;
    const form = this.$refs["bannerForm"] as ElForm;
    if (form && bannerImages.length > 0) {
      form.validateField("bannerImages");
    }
    if (form && videoId.length > 0) {
      form.validateField("videoId");
    }
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  _onInputBlur(value: string, type: string) {
    var flag = false;
    // var value = event.target.value
    if ((value || "").trim().length === 0) {
      flag = true;
    }
    if (type === "title" && this.itemErrorFlags.title !== flag) {
      this.itemErrorFlags.title = flag;
    } else if (type === "btnText" && this.itemErrorFlags.btnText !== flag) {
      this.itemErrorFlags.btnText = flag;
    } else if (
      type === "btnTextBefore" &&
      this.itemErrorFlags.btnTextBefore !== flag
    ) {
      this.itemErrorFlags.btnTextBefore = flag;
    } else if (
      type === "btnTextOnGoing" &&
      this.itemErrorFlags.btnTextOnGoing !== flag
    ) {
      this.itemErrorFlags.btnTextOnGoing = flag;
    } else if (
      type === "btnTextAfter" &&
      this.itemErrorFlags.btnTextAfter !== flag
    ) {
      this.itemErrorFlags.btnTextAfter = flag;
    }
  }

  _onTextInput(value: string, type: string) {
    value = value.trimStart();
    var temp = 0;
    var maxLen = 8;

    if (type === "title") {
      maxLen = 32;
    }

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        value = value.substr(0, i);
      }
    }
    switch (type) {
      case "title":
        this.formParams.title = value;
        break;
      case "btnText":
        this.formParams.btnText = value;
        break;
      case "btnTextBefore":
        this.formParams.btnTextBefore = value;
        break;
      case "btnTextOnGoing":
        this.formParams.btnTextOnGoing = value;
        break;
      case "btnTextAfter":
        this.formParams.btnTextAfter = value;
        break;
      default:
        break;
    }
  }

  _onClickConfirm() {
    const form = this.$refs["bannerForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        this._onInputBlur(this.formParams.title, "title");
        this._onInputBlur(this.formParams.btnText, "btnText");
        this._onInputBlur(this.formParams.btnTextAfter, "btnTextAfter");
        this._onInputBlur(this.formParams.btnTextOnGoing, "btnTextOnGoing");
        this._onInputBlur(this.formParams.btnTextBefore, "btnTextBefore");
        return false;
      }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit"
        ? "确认更新该副Banner吗？"
        : "确认保存为新的副Banner吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    const params = JSON.parse(JSON.stringify(this.formParams));
    const formParams = this.formParams;

    const reqModel: IViceBannerReqModel = {
      type: formParams.type, // 副banner类型
      name: formParams.title,
      imageId: formParams.bannerImages[0].id,
      videoId: formParams.type === 1 ? formParams.videoId : undefined,
      redirectLink: formParams.redirectLink,
      appId: formParams.appId,
      buttonText: formParams.type === 2 ? "" : formParams.btnText,
      roomId: formParams.type === 2 ? parseInt(formParams.roomId) : undefined,
      buttonStatusText:
        formParams.type === 2
          ? `${formParams.btnTextBefore},${formParams.btnTextOnGoing},${formParams.btnTextAfter}`
          : "",
      redirectToCRM: formParams.redirectToCRM,
    };

    this.saveBtnLoading = true;
    if (this.editType === "edit") {
      this._updateBanner(reqModel);
    } else {
      this._createBanner(reqModel);
    }
  }

  // 新增
  async _createBanner(reqModel: IViceBannerReqModel) {
    try {
      const videoRes = await importExternalVideo(reqModel.videoId || "");
      if (videoRes.status !== 200) {
        this.$message.error("视频上传出错，请重试。");
        return;
      }

      reqModel.videoId = videoRes.data.data.id;
      const res = await addViceBanner(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditBannerSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_createBanner出错了");
    }
  }

  // 编辑
  async _updateBanner(reqModel: IViceBannerReqModel) {
    try {
      const videoRes = await importExternalVideo(reqModel.videoId || "");
      if (videoRes.status !== 200) {
        this.$message.error("视频上传出错，请重试。");
        return;
      }

      reqModel.videoId = videoRes.data.data.id;
      const res = await updateViceBanner(this.bannerId, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditBannerSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateBanner 出错了");
    }
  }
  videoUrl: string = "";
  async _previewVideo() {
    const res = await getVideo(this.formParams.videoId);
    if (res?.data?.data?.url) {
      this.videoUrl = res.data.data.url;
      this.previewDialogVisible = true;
    }else{
         this.$message.error("请确认视频id是否正确");
         return
      }
    console.log(res);
  }
}
