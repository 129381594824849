import http from "./interface";
import { UserModule } from "@/store/modules/user";

export const uploadMedias = (data: any) => {
  return http.post(
    `/api/v3/wineries/${UserModule.wineryId}/media/upload`,
    data
  );
};

// // 获取会员级别
// export const getVipLevel = () => {
//   return http.get(`/api/v3/wineries/${UserModule.wineryId}/vipLevel`)
// }

export const getWineryInfo = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}`);
};

export const getDefaultStoreInfo = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/winery-product-inventories/default-setting?productType=ticket`);
};
// 得到预约某一时间段的用户列表
export const getCustomListByDay = (timeSlotId: string, date: string) => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings?timeSlotId=${timeSlotId}&date=${date}&status=valid,completed,expired`
  );
};

// export const getBookingDetailsByDay() {

// }
