import http from './interface'
import { UserModule } from '@/store/modules/user'

import {
  IBannerListQuery, IBannerSortReqModel, IMainBannerReqModel,
  IViceBannerReqModel, IViceBannerTitleReqModel
} from '@/pages/banner/data/bannerModel'

// 获取各个状态的主banner数量
export const getMainBannerCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/banners/primary-banner/count`)
}

export const getMainBannerRecords = (data: IBannerListQuery) => {
  const { type, updatedTime } = data
  const url = `/api/v3/wineries/${UserModule.wineryId}/banners/carousel?type=${type}&updateTime=${updatedTime}`
  return http.get(url)
}

// 取消发布和发布
export const updateMainBannerPublishStatus = (
  bannerId: string,
  whetherPublish: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/banners/carousel/${bannerId}/publish`,
    {
      whetherPublish
    }
  )
}

// 删除
export const deleteMainBanner = (bannerId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/banners/carousel/${bannerId}/delete`)
}

// 详情
export const getMainBannerDetails = (bannerId: string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/banners/carousel/${bannerId}`)
}

// 新增
export const addMainBanner = (data: IMainBannerReqModel) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/banners/carousel`, { ...data })
}

// 修改
export const updateMainBanner = (bannerId: string, data: IMainBannerReqModel) => {
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/banners/carousel/${bannerId}`, {
    ...data
  })
}

// 更新banner展示顺序
export const updateMainBannerListSort = (list: IBannerSortReqModel[]) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/banners/carousel/sort`, list)
}

// ==========================副banner===================
export const getViceBannerRecords = (data: IBannerListQuery) => {
  const { type, updatedTime } = data
  const url = `/api/v3/wineries/${UserModule.wineryId}/banners/secondary?type=${type}&updateTime=${updatedTime}`
  return http.get(url)
}

// 获取各个状态的副banner数量
export const getViceBannerCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/count`)
}

// 取消发布和发布
export const updateViceBannerPublishStatus = (
  bannerId: string,
  whetherPublish: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/banners/secondary/${bannerId}/publish`,
    {
      whetherPublish
    }
  )
}

// 删除
export const deleteViceBanner = (bannerId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/${bannerId}/delete`)
}

// 详情
export const getViceBannerDetails = (bannerId: string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/${bannerId}`)
}

// 创建
export const addViceBanner = (data: IViceBannerReqModel) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary`, { ...data })
}

// 修改
export const updateViceBanner = (bannerId: string, data: IViceBannerReqModel) => {
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/${bannerId}`, {
    ...data
  })
}

export const getViceBannerTitle = () => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/banners/secondary/title`
  return http.get(url)
}

// 修改title
export const updateViceBannerTitle = (data: IViceBannerTitleReqModel) => {
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/title`, {
    ...data
  })
}

// 更新banner展示顺序
export const updateViceBannerListSort = (list: IBannerSortReqModel[]) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/banners/secondary/sort`, list)
}

// 初始化/上视频Id
export const importExternalVideo = (tencentMediaId: string) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/media/import-external-video`, {tencentMediaId})
}