




















import { Component, Vue, Prop } from "vue-property-decorator";

// 装饰器模式
@Component({
  components: {},
})
export default class extends Vue {
  // Props
  // 显示或隐藏
  @Prop({ default: false })
  dialogVisible!: boolean;
  @Prop() mediaPath!: string;
  @Prop({ default: "video" })
  mediaType!: string;

  // Methods
  _beforeModalClose() {
    this._hide();
  }

  _hide() {
    this.$emit("update:dialogVisible", false); // 直接修改父组件的属性
  }

  _onClickCancel() {
    this._hide();
  }
}
