import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { IBannerCountRes } from '@/pages/banner/data/bannerModel'
import { getMainBannerCountByStatus, getViceBannerCountByStatus } from '@/remote/banner'

export interface IBannerState {
  mainBannerCountRes: IBannerCountRes
  viceBannerCountRes: IBannerCountRes
}

@Module({ dynamic: true, store, name: 'mainBanner' })
class Banner extends VuexModule implements IBannerState {
  public mainBannerCountRes = {
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0
  }
  public viceBannerCountRes = {
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0
  }

  @Action
  public async FetchMainBannerCountByStatus() {
    try {
      const res = await getMainBannerCountByStatus()
      if (res && res.data && res.data.data) {
        this.SET_MAIN_BANNER_COUNT_RES(res.data.data)
      }
    } catch {
      // console.log('getMainBannerCountByStatus出错了')
    }
  }

  @Mutation
  SET_MAIN_BANNER_COUNT_RES(res: IBannerCountRes) {
    this.mainBannerCountRes = res
  }

  @Action
  public async FetchViceBannerCountByStatus() {
    try {
      const res = await getViceBannerCountByStatus()
      if (res && res.data && res.data.data) {
        this.SET_VICE_BANNER_COUNT_RES(res.data.data)
      }
    } catch {
      // console.log('getViceBannerCountByStatus出错了')
    }
  }

  @Mutation
  SET_VICE_BANNER_COUNT_RES(res: IBannerCountRes) {
    this.viceBannerCountRes = res
  }
}

export const BannerModule = getModule(Banner)
