






































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import Pagination from "@/components/pagination/Pagination.vue";

import { Table as ElTable } from "element-ui";
import Sortable from "sortablejs"; // 用于拖拽排序

import { IPanelData } from "@/data/commonModel";
import {
  IViceBannerModel,
  IBannerListQuery,
  IBannerSortReqModel,
} from "../data/bannerModel";
import { BannerModule } from "@/store/modules/banner";

import {
  getViceBannerRecords,
  updateViceBannerPublishStatus,
  deleteViceBanner,
  updateViceBannerListSort,
  getViceBannerDetails,
} from "@/remote/banner";
import { getWineryInfo } from "@/remote/common";
import { addClass, removeClass } from "@/utils/tools";

// 装饰器模式
@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  // Props
  @Prop({
    required: true,
  })
  panelObj!: IPanelData;
  // 开始/取消前端显示排序
  @Prop({ default: false })
  isOrdering!: boolean;

  // data
  columnLableWidth: string = "110px";
  smallColumnLableWidth: string = "100px";
  titleColumnLableWidth: string = "180px";

  listLoading: boolean = false;
  list: IViceBannerModel[] = [];
  totalSize = 0;
  listQuery: IBannerListQuery = {
    type: "all",
    updatedTime: "",
  };
  sortable: any = null;
  maxSecondaryBanners: Number = 6;

  // Watch
  @Watch("panelObj", {
    deep: true,
    immediate: true,
  })
  _onPanelObjChange(value: IPanelData) {
    this.listQuery.type = value.type;
    this._fetchBannerRecords({
      ...this.listQuery,
    });
  }

  @Watch("isOrdering", {
    immediate: true,
  })
  onIsOrderingChange(value: boolean) {
    if (this.sortable) {
      this.sortable.option("disabled", !value);
    }
    if (value && this.listQuery.updatedTime !== "") {
      // console.log("清除创建、发布时间排序");
      // 清除创建、发布时间排序
      this.listQuery.updatedTime = "";
      const table = this.$refs["vice-banner-table"] as ElTable;
      table.clearSort();
      this.refreshTable();
    } else if (
      this.sortable &&
      !value &&
      this.listQuery.type === "published"
    ) {
      // 保存排序
      this._updateTableSort();
    }
  }

  // Methods
  mounted() {
    this._rowDrop();
    this._getMaxSecondaryBanners();
  }

  public refreshTable() {
    this._fetchBannerRecords({
      ...this.listQuery,
    });
  }

  async _getMaxSecondaryBanners() {
    try {
      const res = await getWineryInfo();
      if (res && res.data && res.data.data) {
        const { maxSecondaryBanners } = res.data.data;
        this.maxSecondaryBanners = maxSecondaryBanners;
      }
    } catch {
      // console.log("_getMaxSecondaryBanners 出错了");
    }
  }

  async _fetchBannerRecords(params: IBannerListQuery) {
    this.listLoading = true;
    try {
      const res = await getViceBannerRecords(params);
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        data.forEach((element: IViceBannerModel) => {
          element.updatedAtText = moment(element.updatedAt).format(
            "YYYY-MM-DD"
          );
          element.tempName = element.name.length === 0 ? "无" : element.name;
          element.typeDes =
            element.type === 0
              ? "普通链接"
              : element.type === 1
              ? "观看视频"
              : element.type === 2
              ? "微信直播间"
              : "小程序";
        });
        this.list = data;
      } else {
        this.list = [];
      }
    } catch (error) {
      // console.log(error);
      // console.log("getViceBannerRecords 出错了");
    }
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  _onSortChange(column: any) {
    const { order, prop } = column;
    if (!order) {
      this.listQuery.updatedTime = "";
    } else if (order === "descending") {
      this.listQuery.updatedTime = "DESC";
    } else {
      this.listQuery.updatedTime = "ASC";
    }
    this.refreshTable();
  }

  async _onClickEdit(id: string) {
    this.listLoading = true;
    try {
      const res = await getViceBannerDetails(id);
      if (res && res.data && res.data.data) {
        this.$emit("onEditBanner", res.data.data);
      } else {
        this.$message.error("获取首页副Banner详情失败");
      }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      // console.log("getViceBannerDetails 失败了");
    }
  }

  _onChangeSwitch(value: boolean, id: string, index: number) {
    if (
      BannerModule.viceBannerCountRes.publishedCount >=
        this.maxSecondaryBanners &&
      value
    ) {
      this.list[index].isPublished = false;
      this.$message.error("已发布Banner已达上限");
      return;
    }
    this.$confirm("", value ? "确认发布这个Banner吗？" : "确认取消发布吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._onUpdatePublishStatus(value, id, index);
      })
      .catch(() => {
        this.list[index].isPublished = !value;
      });
  }

  async _onUpdatePublishStatus(value: boolean, id: string, index: number) {
    try {
      this.listLoading = true;
      const res = await updateViceBannerPublishStatus(id, value);
      this.listLoading = false;
      if (res.data.resultCode === 0) {
        if (this.listQuery.type === "all") {
          this.list[index].isPublished = value;
        } else {
          this.refreshTable();
        }
        this.$message.success("操作成功");
        BannerModule.FetchViceBannerCountByStatus();
      }
    } catch {
      this.listLoading = false;
    }
  }

  // 删除
  async _onClickDelete(id: string) {
    this.$confirm("", "确认删除这个Banner吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._deleteBanner(id);
      })
      .catch(() => {});
  }

  async _deleteBanner(id: string) {
    try {
      this.listLoading = true;
      const res = await deleteViceBanner(id);
      this.listLoading = false;
      if (res && res.status === 200) {
        this._fetchBannerRecords(this.listQuery);
        this.$message.success("操作成功");
        BannerModule.FetchViceBannerCountByStatus();
      }
    } catch {
      this.listLoading = false;
    }
  }

  //行拖拽
  _rowDrop() {
    const tbody = document.querySelector(".el-table__body-wrapper tbody");
    const _this = this;
    if (!tbody) return;
    this.sortable = new Sortable(tbody, {
      disabled: true,
      onStart: () => {
        addClass(".el-table__body-wrapper tbody", "cursor-move");
        removeClass(".el-table__body-wrapper tbody", "cursor-default");
      },
      onEnd: (event: any) => {
        const { newIndex, oldIndex } = event;
        const currRow = _this.list.splice(oldIndex, 1)[0]; // 删除
        _this.list.splice(newIndex, 0, currRow); // 添加到指定位置
        removeClass(".el-table__body-wrapper tbody", "cursor-move");
        addClass(".el-table__body-wrapper tbody", "cursor-default");
      },
    });
  }

  // 更新展示排序
  async _updateTableSort() {
    const arr: IBannerSortReqModel[] = [];
    this.list.forEach((element: IViceBannerModel, index: number) => {
      arr.push({ id: element.id, order: index + 1 });
    });
    try {
      this.listLoading = true;
      const res = await updateViceBannerListSort(arr);
      if (res && res.status === 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.error("保存失败，请重试");
      }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
      this.$message.error("保存失败，请重试");
      // console.log("updateViceBannerListSort 出错了");
    }
  }
}
