import http from './interface'
import { UserModule } from '@/store/modules/user'
import { IVideoListQuery } from '@/pages/video/data/videoModel'

import { IMediaStoreModel,IMediaStoreReqModel,IMediaStoreQuery } from '@/pages/media-store/data/mediaStoreModel'

export const uploadVideo = (tencentMediaId: string) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/screenvideos/upload?isInternal=false`,{tencentMediaId})
}

export const deleteLandingVideo = (id: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/screenvideos/${id}`)
}

// 获取开屏视频列表
export const getLandingVideoList = (data: IVideoListQuery) => {
  const { searchText, createdTime, page, size } = data
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/screenvideos?fileName=${searchText}&createdTime=${createdTime}&page=${page}&size=${size}`
  )
}

export const updateLandingVideoUseStatus = (id: string, isUsed: boolean) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/screenvideos/${id}`,{isUsed}
  )
}


export const getVideos = (data: IMediaStoreQuery) => {
  Object.assign(data,{wineryId:UserModule.wineryId})
  
  const url = `/api/v3/videos?wineryId=${UserModule.wineryId}&searchText=${data.searchText}`
  return http.get(url)
}

export const addVideo = (data: any) => {
   
  return http.post('/api/v3/videos/upload',data)
}

export const getVideo = (videoId: string) => {
   
  return http.get(`/api/v3/videos/${videoId}`)
}
// 修改
export const updateVideo = (videoId: string, data: IMediaStoreModel) => {
  const { title,description, ...rest } = data;
  const postData ={title,description}
  return http.patch(`/api/v3/videos/${videoId}`, postData)
}

// 删除
export const deleteVideo = (videoId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/package-introductions/${videoId}`)
}

export const updateCoverImage = (vid:string,data: any) => {
   
  return http.post(`/api/v3/videos/${vid}/upload-cover-image`,data)
}

 