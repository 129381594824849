
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
// import ImgUpload from "@components/ImgUpload.vue";
import { Form as ElForm } from "element-ui";
// import { UserModule } from "@/store/modules/user";
// import { IMediaModel } from "@/data/commonModel";
import { getViceBannerTitle, updateViceBannerTitle } from "@/remote/banner";
import {
  IViceBannerTitleReqModel,
  IMainBannerTitleModel,
} from "../data/bannerModel";

interface IBannerTitleFormModel {
  title: string;
  subtitle: string;
  isPublished: boolean;
}

// 装饰器模式
@Component({
  components: {
    ElForm,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "edit",
  })
  editType!: string;
  // editType = 'edit'时所需要的数据
  // @Prop() bannerTitleModel!: IMainBannerTitleModel;

  // Data
  loading: boolean = true;
  formLabelWidth: string = "140px";
  formValueWidth: number = 450;
  formParams: IBannerTitleFormModel = {
    title: "",
    subtitle: "",
    isPublished: false,
  };

  // bannerId: string = "";
  saveBtnLoading: boolean = false;
  titleLength: number = 0;
  subTitleLength: number = 0;
  // videoMaxSize: number = 1024 * 49;

  // itemErrorFlags = {
  // title: false,
  // roomId: false,
  // btnText: false,
  // btnTextBefore: false,
  // btnTextOnGoing: false,
  // btnTextAfter: false,
  // };

  // Rules
  rules = {};

  // Computed
  // get uploadImageItemWidth() {
  //   return `${(this.formValueWidth - 20) / 5}px`;
  // }

  @Watch("visible", {
    immediate: true,
    deep: true,
  })
  async getBannerTitle() {
    try {
      const res = await getViceBannerTitle();
      this.loading = false;
      if (res && res.data && res.data.data) {
        const { title, subtitle, isPublished } = res.data.data;
        this.formParams = { title, subtitle, isPublished };
      } else {
        this.$message.error("获取首页副Banner标题失败");
      }
    } catch {
      // 
    }
  }

  // // Watch
  // @Watch("bannerTitleModel", {
  //   immediate: true,
  //   deep: true,
  // })
  // onBannerModelChange(value: IMainBannerTitleModel) {
  //   if (!value) return;
  //   const model = JSON.parse(JSON.stringify(value));
  //   // this.bannerId = model.id;

  //   // var btnTexts;
  //   // if (model.type === 2 && model.buttonStatusText.length > 0) {
  //   //   btnTexts = model.buttonStatusText.split(",");
  //   // }

  //   this.formParams = {
  //     title: model.title,
  //   };
  // }

  // @Watch("formParams.type", { immediate: true, deep: true })
  // onTypeChange(value: number) {
  //   this.itemErrorFlags = {
  //     title: this.itemErrorFlags.title,
  //     // roomId: false,
  //     // btnText: false,
  //     // btnTextBefore: false,
  //     // btnTextOnGoing: false,
  //     // btnTextAfter: false,
  //   };

  //   const form = this.$refs["bannerTitleForm"] as any;
  //   if (form && value !== 2 && form.fields[3].validateState === "error") {
  //     form.fields[3].validateState = "success";
  //   }
  // }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  // _onInputBlur(value: string, type: string) {
  // var flag = false;
  // // var value = event.target.value
  // if (value.trim().length === 0) {
  //   flag = true;
  // }
  // if (type === "title" && this.itemErrorFlags.title !== flag) {
  //   this.itemErrorFlags.title = flag;
  // }
  //  else if (type === "btnText" && this.itemErrorFlags.btnText !== flag) {
  //   this.itemErrorFlags.btnText = flag;
  // } else if (
  //   type === "btnTextBefore" &&
  //   this.itemErrorFlags.btnTextBefore !== flag
  // ) {
  //   this.itemErrorFlags.btnTextBefore = flag;
  // } else if (
  //   type === "btnTextOnGoing" &&
  //   this.itemErrorFlags.btnTextOnGoing !== flag
  // ) {
  //   this.itemErrorFlags.btnTextOnGoing = flag;
  // } else if (
  //   type === "btnTextAfter" &&
  //   this.itemErrorFlags.btnTextAfter !== flag
  // ) {
  //   this.itemErrorFlags.btnTextAfter = flag;
  // }
  // }

  _onTextInput(value: string, type: "title" | "subtitle") {
    value = value.trimStart();
    var temp = 0;
    var maxLen = 32;

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        value = value.substr(0, i);
      }
    }

    this.formParams[type] = value;
  }

  _onClickConfirm() {
    const form = this.$refs["bannerTitleForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      }
      // else {
      //   this._onInputBlur(this.formParams.title, "title");
      //   return false;
      // }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit" ? "确认更新吗？" : "确认保存为新的副Banner吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    const reqModel: IViceBannerTitleReqModel = this.formParams;

    this.saveBtnLoading = true;
    this._updateBannerTitle(reqModel);
    // if (this.editType === "edit") {
    //   this._updateBannerTitle(reqModel);
    // } else {
    //   // this._createBanner(reqModel);
    // }
  }

  // 新增
  // async _createBanner(reqModel: IViceBannerTitleReqModel) {
  //   try {
  //     const res = await addViceBanner(reqModel);
  //     if (res.status === 200) {
  //       this.$message.success("保存成功");
  //       this.$emit("onEditBannerSuccess", this.editType);
  //       this._hide();
  //     }
  //     this.saveBtnLoading = false;
  //   } catch {
  //     this.saveBtnLoading = false;
  //     // console.log("_createBanner出错了");
  //   }
  // }

  // 编辑
  async _updateBannerTitle(reqModel: IViceBannerTitleReqModel) {
    try {
      const res = await updateViceBannerTitle(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        // this.$emit("onEditBannerTitleSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateBanner 出错了");
    }
  }
}
